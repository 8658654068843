<template>
  <div @contextmenu.prevent="showContextMenu($event)">
    <div @click="showContextMenu($event)">
      <slot></slot>
    </div>
    <div
      v-if="isContextMenuVisible && isCurrent"
      class="context-menu"
      :style="`
      right: ${contextMenuStyle.right};
      left: ${contextMenuStyle.left};
      bottom: ${contextMenuStyle.bottom};`"
    >
      <div class="item emojis" v-if="showEmoji">
        <span @click="onClickEmoji('👍')">👍</span>
        <span @click="onClickEmoji('❤️')">❤️</span>
        <span @click="onClickEmoji('😂')">😂</span>
        <span @click="onClickEmoji('😮')">😮</span>
        <span @click="onClickEmoji('😢')">😢</span>
        <span @click="onClickEmoji('🙏')">🙏</span>
      </div>
      <div
        v-for="({ ico, label, action, show }, index) in menuItems"
        :key="index"
      >
        <div class="item" @click="handleMenuItemClick(action)" v-if="show">
          <a-icon v-if="ico" :type="ico" /> {{ label }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: String,
    menuItems: Array,
    isCurrent: Boolean,
    data: Object,
    showLeft: Boolean,
    showRight: Boolean,
    showTop: Boolean,
    showEmoji: Boolean,
  },
  data() {
    return {
      isContextMenuVisible: false,
      contextMenuStyle: {
        left: "0px",
        right: "0px",
        bottom: "unset",
      },
    };
  },
  mounted() {
    if (this.showRight) {
      this.contextMenuStyle.right = "0px";
      this.contextMenuStyle.left = "unset";
    }

    if (this.showTop) {
      this.contextMenuStyle.bottom = "27px";
    }
  },
  methods: {
    showContextMenu(event) {
      event.preventDefault();
      console.log("showContextMenu", event.pageY, event.pageX);
      this.isContextMenuVisible = true;
      this.contextMenuStyle.top = event.pageY + "px";
      //this.contextMenuStyle.bottom = event.pageY + "px";
      // this.contextMenuStyle.left = event.pageX + "px";
      // this.contextMenuStyle.right = event.pageX + "px";
      // Add click event listener to the document
      document.addEventListener("click", this.hideContextMenu, this.isCurrent);
    },
    onClickEmoji(emoji) {
      this.$emit("onClickEmoji", emoji);
    },
    hideContextMenu(event) {
      // Check if the clicked element is not inside the context menu
      if (!event.target.closest(".context-menu")) {
        this.isContextMenuVisible = false;
        // Remove click event listener from the document
        document.removeEventListener("click", this.hideContextMenu);
      }
    },
    handleMenuItemClick(action) {
      // Handle menu item click actions here
      console.log("Clicked on", this.id, action);
      this.$emit("onClickMenuItem", this.id, action, this.data);
      this.isContextMenuVisible = false;
    },
  },
};
</script>

<style lang="sass" scoped>
.context-menu
  position: absolute
  background-color: white
  border: 1px solid #ddd
  z-index: 1000
  width: 220px
  padding: 0
  text-align: left
  border-radius: 6px
  font-size: 13px
  box-shadow: 0px 6px 10px 3px rgb(0 0 0 / 5%)
  overflow: hidden
  .emojis
    cursor: inherit
    font-size: 20px
  .item
    cursor: pointer
    padding: 10px
    border-bottom: 1px solid #eee
    transition: .3s
    background: #fff
  .item:hover
    background: #fafafa
</style>
