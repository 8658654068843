<template>
  <div
    :id="message._id"
    class="message"
    :class="currentUserId == message.senderId ? 'current' : ''"
  >
    <aRow
      v-if="currentUserId !== message.senderId && !message.system"
      type="flex"
      align="middle"
    >
      <aCol :span="24">
        <a-row type="flex" justify="start" :gutter="[10, 10]">
          <a-col v-if="showMessagesAvatar">
            <a-avatar icon="user" :src="message.avatar" :size="30" />
          </a-col>
          <a-col class="relative" :span="16">
            <div class="card">
              <div
                v-if="message.replyMessage"
                class="reply-message"
                @click="`#${message.replyMessage._id}`"
              >
                <div class="name">{{ message.replyMessage.username }}</div>
                <div>{{ message.replyMessage.content }}</div>
              </div>
              <font
                v-if="
                  regex.test(message.content) && message.content.length === 2
                "
                style="font-size: 60px"
                >{{ message.content }} </font
              ><font v-else>{{ message.content }}</font>
              <div class="time">
                <font v-if="currentUserId === message.senderId" class="status">
                  <a-icon class="check-one" type="check" />
                  <a-icon class="check-two" type="check" />
                </font>
                {{ message.timestamp }}
              </div>
            </div>
            <div class="message-actions">
              <ChatContextMenu
                :id="message._id"
                :data="message"
                :isCurrent="activeAction === message._id"
                :showTop="
                  messages.length == index + 1 ||
                  messages.length - 1 == index + 1
                    ? true
                    : false
                "
                :showEmoji="true"
                :menuItems="[
                  {
                    label: 'Responder',
                    ico: 'select',
                    action: 'reply',
                    show: true,
                  },
                  {
                    label: 'Copiar',
                    ico: 'copy',
                    action: 'copy',
                    show: true,
                  },
                ]"
                @onClickMenuItem="onClickMenuItem"
                @onClickEmoji="onClickSendReaction"
              >
                <div
                  @click="onClickChangeActiveAction(message._id)"
                  class="icos"
                >
                  <a-icon type="smile" /> <a-icon type="down" />
                </div>
              </ChatContextMenu>
            </div>
          </a-col>
        </a-row>
      </aCol>
    </aRow>

    <aRow
      v-if="currentUserId === message.senderId && !message.system"
      type="flex"
      align="middle"
    >
      <aCol :span="24">
        <aRow type="flex" justify="end" :gutter="[10, 10]" align="middle">
          <a-col class="relative" :span="16">
            <div class="message-actions">
              <ChatContextMenu
                :id="message._id"
                :data="message"
                :isCurrent="activeAction === message._id"
                :showRight="true"
                :showTop="
                  messages.length == index + 1 ||
                  messages.length - 1 == index + 1
                    ? true
                    : false
                "
                :showEmoji="true"
                :menuItems="[
                  {
                    label: 'Responder',
                    ico: 'select',
                    action: 'reply',
                    show: true,
                  },
                  {
                    label: 'Copiar',
                    ico: 'copy',
                    action: 'copy',
                    show: true,
                  },
                ]"
                @onClickMenuItem="onClickMenuItem"
                @onClickEmoji="onClickSendReaction"
              >
                <div
                  @click="onClickChangeActiveAction(message._id)"
                  class="icos"
                >
                  <a-icon type="smile" /> <a-icon type="down" />
                </div>
              </ChatContextMenu>
            </div>
            <div class="card">
              <div
                v-if="message.replyMessage"
                class="reply-message"
                @click="`#${message.replyMessage._id}`"
              >
                <div class="name">{{ message.replyMessage.username }}</div>
                <div>{{ message.replyMessage.content }}</div>
              </div>
              <div>
                <font
                  v-if="
                    regex.test(message.content) && message.content.length === 2
                  "
                  style="font-size: 60px"
                  >{{ message.content }} </font
                ><font v-else>{{ message.content }} </font>
              </div>
              <div class="time">
                {{ message.timestamp }}
                <font v-if="currentUserId === message.senderId" class="status">
                  <a-icon class="check-one" type="check" />
                  <a-icon class="check-two" type="check" />
                </font>
              </div>
            </div>
          </a-col>
          <a-col v-if="showMessagesAvatar">
            <a-avatar icon="user" :src="message.avatar" :size="30" />
          </a-col>
        </aRow>
      </aCol>
    </aRow>

    <aRow v-if="message.system" type="flex" align="middle">
      <aCol :span="24" class="system">
        <span class="text">{{ message.content | formatSystemDate }}</span>
      </aCol>
    </aRow>
  </div>
</template>

<script>
const regex = /\p{Extended_Pictographic}/gu;
import ChatContextMenu from "./ChatContextMenu.vue";

export default {
  name: "MessageBallon",
  props: {
    message: Object,
    messages: Array,
    index: Number,
    currentUserId: String,
    activeAction: String,
  },
  components: { ChatContextMenu },
  data() {
    return {
      regex,
      openMessageActions: false,
    };
  },
  filters: {
    formatSystemDate(dateString) {
      const inputDate = new Date(dateString);
      const currentDate = new Date();

      // Check if the input date is today
      if (
        inputDate.getDate() === currentDate.getDate() &&
        inputDate.getMonth() === currentDate.getMonth() &&
        inputDate.getFullYear() === currentDate.getFullYear()
      )
        return "Hoje";

      // Check if the input date is yesterday
      const yesterday = new Date();
      yesterday.setDate(currentDate.getDate() - 1);
      if (
        inputDate.getDate() === yesterday.getDate() &&
        inputDate.getMonth() === yesterday.getMonth() &&
        inputDate.getFullYear() === yesterday.getFullYear()
      )
        return "Ontem";

      // If it's not today or yesterday, return the formatted date (MM/DD/YYYY)
      const formattedDate =
        `${(inputDate.getMonth() + 1).toString().padStart(2, "0")}/` +
        `${inputDate.getDate().toString().padStart(2, "0")}/` +
        `${inputDate.getFullYear()}`;

      return formattedDate;
    },
  },
  methods: {
    onClickChangeActiveAction(id) {
      this.$emit("onClickChangeActiveAction", id);
    },
    onClickSendReaction(message, emoji) {
      this.$emit("onClickSendReaction", message, emoji);
      this.$emit("onClickChangeActiveAction", "");
    },
    onClickRespondMessage(message) {
      this.$emit("onClickRespondMessage", message);
      this.$emit("onClickChangeActiveAction", "");
    },
    onClickCopyMessage(message) {
      navigator.clipboard.writeText(message.content);
      this.$emit("onClickChangeActiveAction", "");
    },
    onClickMenuItem(id, action, data) {
      if (action === "copy") this.onClickCopyMessage(data);
      if (action === "reply") this.onClickRespondMessage(data);
    },
  },
};
</script>

<style lang="sass" scoped>

.message-actions
  display: inline-block
  background: #eee
  padding: 1px 5px
  border-radius: 20px
  border: 1px solid #eee
  position: relative
  .icos
    cursor: pointer
    display: none
  .list
    background: #fff
    width: 250px
    padding: 0
    margin: 0
    list-style: none
    position: absolute
    top: 27px
    text-align: left
    left: 0
    z-index: 999
    border-radius: 6px
    border: 1px solid #ccc
    li.reactions
      cursor: inherit
      font-size: 20px
      span
        cursor: pointer

    li
      cursor: pointer
      padding: 10px
      border-bottom: 1px solid #eee
  i
    margin: 0 1px

.message:hover
  .icos
    display: block
.message.current
  .list
    right: 0
    left: auto
.message
  margin-bottom: 10px
  .system
    text-align: center
    .text
      box-shadow: 0 1px 1px -1px #0000001a, 0 1px 1px -1px #0000001c, 0 1px 2px -1px #0000001c
      border-radius: 6px
      background: rgba(0, 0, 0, 0.5)
      padding: 5px 10px
      font-size: 12px
      color: #FFF

  .card
    display: inline-block
    background-color: #aaa
    color: #fff
    border-radius: 6px
    font-size: 14px
    padding: 6px 6px 3px
    max-width: 100%
    -webkit-transition-property: box-shadow,opacity
    transition-property: box-shadow,opacity
    transition: box-shadow .28s cubic-bezier(.4,0,.2,1)
    will-change: box-shadow
    box-shadow: 0 1px 1px -1px #0000001a, 0 1px 1px -1px #0000001c, 0 1px 2px -1px #0000001c
    .reply-message
      padding: 5px
      font-size: 12px
      background: rgba(0, 0, 0, 0.3)
      border-left: 4px solid rgba(0, 0, 0, 0.1)
      border-radius: 6px
      text-align: left
      margin-bottom: 5px
      .name
        font-weight: 500
        font-size: 10px

  .time
    font-size: 10px
    color: #fff
    opacity: .6
    .status
      .check-two
        margin-left: -9px
.message.current
  text-align: right
  .card
    background-color: #a17be0
  .time
    text-align: right
</style>
