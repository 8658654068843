var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"message",class:_vm.currentUserId == _vm.message.senderId ? 'current' : '',attrs:{"id":_vm.message._id}},[(_vm.currentUserId !== _vm.message.senderId && !_vm.message.system)?_c('aRow',{attrs:{"type":"flex","align":"middle"}},[_c('aCol',{attrs:{"span":24}},[_c('a-row',{attrs:{"type":"flex","justify":"start","gutter":[10, 10]}},[(_vm.showMessagesAvatar)?_c('a-col',[_c('a-avatar',{attrs:{"icon":"user","src":_vm.message.avatar,"size":30}})],1):_vm._e(),_vm._v(" "),_c('a-col',{staticClass:"relative",attrs:{"span":16}},[_c('div',{staticClass:"card"},[(_vm.message.replyMessage)?_c('div',{staticClass:"reply-message",on:{"click":function($event){`#${_vm.message.replyMessage._id}`}}},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.message.replyMessage.username))]),_vm._v(" "),_c('div',[_vm._v(_vm._s(_vm.message.replyMessage.content))])]):_vm._e(),_vm._v(" "),(
                _vm.regex.test(_vm.message.content) && _vm.message.content.length === 2
              )?_c('font',{staticStyle:{"font-size":"60px"}},[_vm._v(_vm._s(_vm.message.content)+" ")]):_c('font',[_vm._v(_vm._s(_vm.message.content))]),_vm._v(" "),_c('div',{staticClass:"time"},[(_vm.currentUserId === _vm.message.senderId)?_c('font',{staticClass:"status"},[_c('a-icon',{staticClass:"check-one",attrs:{"type":"check"}}),_vm._v(" "),_c('a-icon',{staticClass:"check-two",attrs:{"type":"check"}})],1):_vm._e(),_vm._v("\n              "+_vm._s(_vm.message.timestamp)+"\n            ")],1)],1),_vm._v(" "),_c('div',{staticClass:"message-actions"},[_c('ChatContextMenu',{attrs:{"id":_vm.message._id,"data":_vm.message,"isCurrent":_vm.activeAction === _vm.message._id,"showTop":_vm.messages.length == _vm.index + 1 ||
                _vm.messages.length - 1 == _vm.index + 1
                  ? true
                  : false,"showEmoji":true,"menuItems":[
                {
                  label: 'Responder',
                  ico: 'select',
                  action: 'reply',
                  show: true,
                },
                {
                  label: 'Copiar',
                  ico: 'copy',
                  action: 'copy',
                  show: true,
                },
              ]},on:{"onClickMenuItem":_vm.onClickMenuItem,"onClickEmoji":_vm.onClickSendReaction}},[_c('div',{staticClass:"icos",on:{"click":function($event){return _vm.onClickChangeActiveAction(_vm.message._id)}}},[_c('a-icon',{attrs:{"type":"smile"}}),_vm._v(" "),_c('a-icon',{attrs:{"type":"down"}})],1)])],1)])],1)],1)],1):_vm._e(),_vm._v(" "),(_vm.currentUserId === _vm.message.senderId && !_vm.message.system)?_c('aRow',{attrs:{"type":"flex","align":"middle"}},[_c('aCol',{attrs:{"span":24}},[_c('aRow',{attrs:{"type":"flex","justify":"end","gutter":[10, 10],"align":"middle"}},[_c('a-col',{staticClass:"relative",attrs:{"span":16}},[_c('div',{staticClass:"message-actions"},[_c('ChatContextMenu',{attrs:{"id":_vm.message._id,"data":_vm.message,"isCurrent":_vm.activeAction === _vm.message._id,"showRight":true,"showTop":_vm.messages.length == _vm.index + 1 ||
                _vm.messages.length - 1 == _vm.index + 1
                  ? true
                  : false,"showEmoji":true,"menuItems":[
                {
                  label: 'Responder',
                  ico: 'select',
                  action: 'reply',
                  show: true,
                },
                {
                  label: 'Copiar',
                  ico: 'copy',
                  action: 'copy',
                  show: true,
                },
              ]},on:{"onClickMenuItem":_vm.onClickMenuItem,"onClickEmoji":_vm.onClickSendReaction}},[_c('div',{staticClass:"icos",on:{"click":function($event){return _vm.onClickChangeActiveAction(_vm.message._id)}}},[_c('a-icon',{attrs:{"type":"smile"}}),_vm._v(" "),_c('a-icon',{attrs:{"type":"down"}})],1)])],1),_vm._v(" "),_c('div',{staticClass:"card"},[(_vm.message.replyMessage)?_c('div',{staticClass:"reply-message",on:{"click":function($event){`#${_vm.message.replyMessage._id}`}}},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.message.replyMessage.username))]),_vm._v(" "),_c('div',[_vm._v(_vm._s(_vm.message.replyMessage.content))])]):_vm._e(),_vm._v(" "),_c('div',[(
                  _vm.regex.test(_vm.message.content) && _vm.message.content.length === 2
                )?_c('font',{staticStyle:{"font-size":"60px"}},[_vm._v(_vm._s(_vm.message.content)+" ")]):_c('font',[_vm._v(_vm._s(_vm.message.content)+" ")])],1),_vm._v(" "),_c('div',{staticClass:"time"},[_vm._v("\n              "+_vm._s(_vm.message.timestamp)+"\n              "),(_vm.currentUserId === _vm.message.senderId)?_c('font',{staticClass:"status"},[_c('a-icon',{staticClass:"check-one",attrs:{"type":"check"}}),_vm._v(" "),_c('a-icon',{staticClass:"check-two",attrs:{"type":"check"}})],1):_vm._e()],1)])]),_vm._v(" "),(_vm.showMessagesAvatar)?_c('a-col',[_c('a-avatar',{attrs:{"icon":"user","src":_vm.message.avatar,"size":30}})],1):_vm._e()],1)],1)],1):_vm._e(),_vm._v(" "),(_vm.message.system)?_c('aRow',{attrs:{"type":"flex","align":"middle"}},[_c('aCol',{staticClass:"system",attrs:{"span":24}},[_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm._f("formatSystemDate")(_vm.message.content)))])])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }